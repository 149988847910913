<template>
  <div class="action-buttons">
    <a
      v-on:click.prevent="handleButtonClick"
      href="#"
      :class="['btn', buttonClass]"
      role="button"
      :title="buttonTitle"
    >
      <i :class="['zmdi', buttonIcon]"></i>
      {{ buttonTitle }}
    </a>
  </div>
</template>

<script>
export default {
  name: 'ActionsButton',
  props: {
    banner: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      buttonTypes: {
        'TO_LIVE': {
          defaulText: "Ver ahora",
          icon: 'zmdi-play-circle'
        },
        'PLAY_VIDEO': {
          defaulText: "Ver ahora",
          icon: 'zmdi-play-circle'
        },
        'TO_MOVIE': {
          defaulText: "Más info",
          btnClass: 'btn-secondary',
        },
        'TO_SERIE': {
          defaulText: "Más info",
          btnClass: 'btn-secondary',
        },
        'TO_EPISODE': {
          defaulText: "Más info",
          btnClass: 'btn-secondary',
        },
        'TO_GUIDE': {
          defaulText: "Más info",
          btnClass: 'btn-secondary',
        },
      }
    };
  },
  computed: {
    buttonClass() {
      return this.buttonTypes[this.currentButtonType]?.btnClass || 'btn-primary';
    },
    buttonTitle() {
      return this.banner.text || this.buttonTypes[this.currentButtonType]?.defaulText || 'Más info';
    },
    buttonIcon() {
      return this.buttonTypes[this.currentButtonType]?.icon || 'zmdi-plus';
    },
    currentButtonType() {
      const { target } = this.banner || {};
      if (target && target.type) {
        const buttonsTypes = {
          live: 'TO_LIVE',
          guide: 'TO_GUIDE',
          video: 'PLAY_VIDEO',
        };

        if (target.appId === 'tplay' && target.appParams) {
          const { screenId } = target.appParams;

          if (screenId === 'movieInformationsScreen') {
            return 'TO_MOVIE';
          } else if (screenId === 'serieInformationsScreen' || screenId === 'serieDescriptionCoverflowScreen') {
            return 'TO_SERIE';
          } else if (screenId === 'episodeInformationsScreen') {
            return 'TO_EPISODE';
          }
        }

        return buttonsTypes[target.type] || null;
      }

      return null;
    },
  },
  methods: {
    sendGrafanaEvent(data) {
      const self = this;
      tplay.sendGrafanaEvent(
        tplay.grafana.event.BUTTON,
        {
          name: data.name,
          sub_type: 'banner',
          screen: self.$route.name
        },
        tplay.grafana.actions.ENTER
      );
    },
    errorCallback(error) {
      this.$bus.$emit('show-toast', 'error', error.message);
    },
    handleButtonClick() {
      const self = this;
      const { target } = this.banner;
      const { peliculas, series, epg } = telecentro.tplay.core.biz;

      switch (self.currentButtonType) {
        case 'TO_LIVE':
          epg.obtenerProgramaActual(target.channelId, (data) => {
            self.sendGrafanaEvent(data);
            self.$bus.$emit('player:set-source', data);
          }, self.errorCallback);
          break;
        case 'TO_MOVIE':
          peliculas.datosDePelicula(target.appParams.mediaId, (data) => {
            self.sendGrafanaEvent(data);
            self.$bus.$emit('modal-movie:show', { data });
          }, self.errorCallback);
          break;
        case 'TO_SERIE':
          series.datosDeSerie(target.appParams.mediaId, (data) => {
            self.sendGrafanaEvent(data);
            self.$bus.$emit('modal-serie:show', { data });
          }, self.errorCallback);
          break;
        case 'TO_EPISODE':
          series.datosDeCapitulo(target.appParams.mediaId, (data) => {
            self.sendGrafanaEvent(data);
            self.$bus.$emit('modal-group-vod:show', { data });
          }, self.errorCallback);
          break;
        case 'TO_GUIDE':
          self.$router.push('/guia');
          break;
        case 'PLAY_VIDEO':
          console.log("PLAY_VIDEO");
          break;
        default:
          console.log('nothing to do...');
          break;
      }
    },
  },
};
</script>
