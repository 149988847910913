<template>
  <ul class="banner-carrousel--items">
    <li v-for="(item, index) in items" :key="index" class="banner-item">
      <img
        :src="item.imageUrl"
        :alt="item.alt"
        :width="item.width"
        :height="item.height"
      />
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
