<template>
  <input
    class="banner-carrousel--activator"
    type="radio"
    name="carousel-activator"
    :id="id"
    :checked="isChecked"
    @change="handleChange"
  />
</template>

<script>
export default {
  name: 'BannerActivator',
  props: {
    id: {
      type: String,
      required: true,
    },
    isChecked: {
      type: Boolean,
      required: true,
    },
    position: {
      type: Number,
      required: true,
    }
  },
  methods: {
    handleChange() {
      this.$emit('change-index', this.position);
    },
  },
};
</script>
