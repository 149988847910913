<template>
  <div class="banner-container">
    <div class="banner-carrousel banner-carrousel--translate">
      <template v-for="(item, index) of banners">
        <BannerActivator
          :key="index"
          :id="`sf-${index}`"
          :position="index"
          :isChecked="currentIndex === index"
          @change-index="changeIndex"
        />
        <arrow-controls :currentIndex="currentIndex" :upperLimit="bannerLength" />
      </template>
      <carousel-items :items="banners" />
      <dots-navigation :dotsCount="bannerLength" />
      <actions-button v-if="hasActionButton" :banner="banners[currentIndex]" />
    </div>
  </div>
</template>

<script>
import DotsNavigation from '@/components/SliderFull/DotsNavigation.vue';
import ActionsButton from '@/components/SliderFull/ActionsButton.vue';
import ArrowControls from '@/components/SliderFull/ArrowControls.vue';
import CarouselItems from '@/components/SliderFull/CarouselItems.vue';
import BannerActivator from '@/components/SliderFull/BannerActivator.vue';

export default {
  name: "SliderFull",
  components: {
    DotsNavigation,
    ActionsButton,
    ArrowControls,
    CarouselItems,
    BannerActivator
  },
  props: {
    banners: {
      type: Array,
      default: [],
      required: true,
    },
    msTransition: {
      type: Number,
      default: 5000,
      required: false,
    },
  },
  data() {
    return {
      currentIndex: 0, 
      timerTransition: null,
    };
  },
  created() {
    this.changeIndex(0);
  },
  computed: {
    hasActionButton() {
      const { target } = this.banners[this.currentIndex];
      return  target && target.type;
    },
    bannerLength() {
      return this.banners.length
    }
  },
  methods: {
    changeIndex(index) {    
      this.deleteIntervalSlide();
      this.currentIndex = index;
      this.createIntervalSlider();
    },
    createIntervalSlider() {
      const self = this;
      self.timerTransition = setInterval(function(){
        if (self.currentIndex < self.bannerLength - 1) {
          self.currentIndex += 1
        } else {
          self.currentIndex = 0
        }
      }, self.msTransition)
    },
    deleteIntervalSlide() {
      clearInterval(this.timerTransition);
    }
  }
};
</script>
