<template>
  <div class="banner-carrousel--controls">
    <label
      v-if="showBackwardControl"
      class="banner-carrousel--control cc--backward"
      :for="`sf-${backwardIndex}`"
    ></label>
    <label
      v-if="showForwardControl"
      class="banner-carrousel--control cc--forward"
      :for="`sf-${forwardIndex}`"
    ></label>
  </div>
</template>

<script>
export default {
  name: "ArrowControls",
  props: {
    currentIndex: {
      type: Number,
      required: true,
    },
    upperLimit: {
      type: Number,
      required: true,
    },
    lowerLimit: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    showBackwardControl() {
      return this.currentIndex > this.lowerLimit;
    },
    showForwardControl() {
      return this.currentIndex < this.upperLimit - 1;
    },
    backwardIndex() {
      return this.currentIndex - 1;
    },
    forwardIndex() {
      return this.currentIndex + 1;
    },
  },
};
</script>
