<template>
  <div class="dots-navigation">
    <label
      v-for="index in dotsCount"
      :key="index"
      :for="`sf-${index - 1}`"
      class="dots-navigation--item"
    ></label>
  </div>
</template>

<script>
export default {
  name: "DotsNavigation",
  props: {
    dotsCount: {
      type: Number,
      required: true,
    },
  },
};
</script>
